<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Role</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-text-field
                v-model="form.name"
                :rules="[v => !!v || 'Role name is required']"
                label="Role Name"
                outlined
                required
                dense
            />

            <div
              v-for="(permissions, permissionGroup) in groupedPermissions"
              :key="permissionGroup"
            >
              <h2>{{ permissionGroup }}</h2>
              <v-row>
                <v-col
                  v-for="permission in permissions"
                  :key="permission.id"
                  :cols="6"
                  class="py-0 d-flex pr-4"
                >
                  <v-checkbox
                    v-model="form.permissions"
                    :label="permission.name"
                    :value="permission"
                    class="mr-2"
                  />

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="grey"
                        small
                        dense
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ permission.description }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-divider class="mb-6 mt-3" />
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" text @click="createRole">Add Role <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'

export default {
  name: 'AddRoleDialog',

  mixins: [ HasDialog, HasForm ],

  data () {
    return {
      permissions: [],
      form: {
        permissions: []
      }
    }
  },

  mounted () {
    this.getPermissions()
  },

  computed: {
    groupedPermissions () {
      var permissions = {}
      this.permissions.forEach(permission => {
        if (!permissions[permission.group]) {
          permissions[permission.group] = []
        }
        permissions[permission.group].push(permission)
      })
      return permissions
    }
  },

  methods: {
    createRole () {
      this.$api.persist('post', {
        path: 'roles',
        object: this.form
      })
    },

    getPermissions () {
      this.$api.get({
        path: 'permissions',
        params: {
          limit: 100 * 1000
        }
      })
        .then(({ data: permissions }) => {
          this.permissions = permissions
        })
    }
  }
}
</script>
