<template>
  <div>
    <v-btn
      class="mt-4"
      color="primary"
      @click="showAddDialog = true"
    >
      Add Role
      <v-icon
        small
        right
      >
        mdi-plus-circle-outline
      </v-icon>
    </v-btn>

    <DataTable
      :cols="cols"
      default-order-by="created_at"
      path="roles"
    >
      <template v-slot:item.name="{ item }">
        <router-link :to="{ name: 'roles.show', params: { roleId: item.id } }">
          {{ item.name }}
        </router-link>
      </template>
    </DataTable>

    <AddRoleDialog
      :dialog.sync="showAddDialog"
    />
  </div>
</template>
<script type="text/javascript">
import DataTable from '@/components/DataTable'
import AddRoleDialog from '@/components/roles/AddRoleDialog'

export default {
  name: 'Roles',

  components: {
    DataTable,
    AddRoleDialog
  },

  data () {
    return {
      showAddDialog: false,
      cols: [
        { text: 'Name', value: 'name' }
      ]
    }
  }
}
</script>
